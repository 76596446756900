.basic-multi-select.is-danger .select__control{
  border-color: hsl(348, 100%, 61%) !important;
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}

.full-notification{
  width: 100%;
}

.blob {
  background: hsl(348, 100%, 61%);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 56, 96, 1);
  height: 15px;
  width: 15px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
  display: inline-block;
  margin-bottom: 1px;
  margin-left: 6px;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 56, 96, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 56, 96, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 56, 96, 0);
  }
}

.ReactVirtualized__Table__headerRow {
  text-transform: capitalize !important;
  border-bottom: 2px solid #ddd;
  margin-bottom: 6px;
}

.st-tr{
  border-bottom: 1px solid #dddddd;
}

.st-tr:hover {
  background-color: #f5f5f5;
}

.st-tr:nth-child(2n) {
  background-color: #fafafa;
}